@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700');

* {
  margin: 0;
  padding: 0; }

$hauptfarbe: #0080bc;
$buttonfarbe: #0080bc;
$hilightfarbe: #FF4262;
$errorcolor: #ff0000;
$feedbackcolor: #32Cd32;
$footerfarbe: #444;
$schriftfarbe: #333;
$navifarbe: #0080bc;
$grauton: #f5f5f5;
$gruenton: #139506;
$blauheading: #0170C0;

//$gruenton: #00B200
//$gruenton: #90BB23
//$gruenton: #39B654
//$navifarbe: #001653
//$hauptfarbe: #41C312 / / #00BAFC
body {
  font-family: 'Raleway', sans-serif;
  background: #fff; }


p, li {
  line-height: 30px; }
a {
  color: $hauptfarbe; }

.glyphicon {
  color: $hauptfarbe; }

.highlight {
  background: $gruenton !important;
  border: 0 !important; }

.titelllink {
  font-weight: 500; }

.multiselect-container>li>a>label {
  height: 30px !important; }

.navizeile {
  width: 100%;
  position: fixed;
  top: 0;
  height: 50px;
  opacity: 0.9;
  z-index: 1000;
  border-bottom: 1px solid #999999; }


.navizeile_farbig {
  transition: all 0.5s ease-in-out;
  background: $navifarbe;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(26, 21, 18, 0.5); }


.navbar a {
  color: #fff;
  &:hover {
    border-bottom: 4px solid $hauptfarbe;
    transition: all 0.5s ease-in; } }



.nav {
  > li > a {
    &:focus, &:hover {
      background: #333;
      color: #fff; } }


  .active {
    background: #333;
    color: #fff;
    border-bottom: 4px solid $hauptfarbe; } }


.heading-farbig {
  background: $blauheading !important;
  color: #fff !important; }

.boxdrueber {
  background: #e1e1e1 !important;
  line-height: 18px; }

.callaction {
  /*margin-left: -50px*/
  /*font-size: 1.5em*/
  border: 0px !important; }

.small {
  padding: 5px 20px !important;
  width: 100px;
  margin-bottom: 10px; }

.hat_fehler {
  color: #a94442 !important;
  border: 1px #a94442 solid !important; }

.hero {
  position: absolute;
  width: 1140px;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%); }


.hero_detailseiteX {
  position: absolute;
  width: 600px;
  top: 15%;
  left: 45%;
  transform: translate(-20%, -40%);
  text-align: center; }

.hero_detailseite {
  width: 100%;
  margin-top: 100px;
  text-align: center;
  margin-left: -20px;
  .logo {
    img {
      max-width: 450px; } }
  span {
    color: #fff;
    font-size: 20px; } }

.hero h1, .hero_detailseite h1 {
  font-weight: 600; }

#hero h2 {
  color: #ffffff !important; }

.easy-autocomplete-container {
  color: #000000 !important;
  z-index: 100000 !important; }


header {
  /*background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url(../img/mojob_startseite.jpg)*/
  height: 100vh;
  background-position: center;
  background-size: cover; }

.header_unternehmen {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(../img/arbeitgeber.jpg);
  height: 100vh;
  background-position: center;
  background-size: cover; }

.headerjobdetails {
  margin-top: 50px;
  height: 40vh;
  background-position: center;
  background-size: cover; }

.jobdetails {
  h3:after {
    width: 100px;
    height: 2px;
    /*background-color: $hauptfarbe*/
    display: block;
    content: " ";
    margin: 0 auto;
    margin-top: 30px; }
  .titel {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 100; } }
.fixed {
  z-index: 99999 !important; }

#infos {
  span {
    color: $hauptfarbe;
    line-height: 25px; }
  ul {
    list-style: none; }
  li,p {
    font-size: 15px !important; } }

h1 {
  color: white; }

.detailbutton {
  font-size: 14px;
  width: 100%; }

.navbar-header i {
  font-size: 30px;
  color: #fff; }


.logo {
  color: white; }


.btn {
  border: 1px solid $hauptfarbe;
  padding: 10px 40px;
  color: $hauptfarbe;
  text-decoration: none;
  margin-right: 1px; }


.button-awesome {
  margin-top: 40px; }

.btn-full {
  width: 100%; }

.btn-half, .btn-full {
  background-color: $buttonfarbe;
  color: white;
  border-radius: 3px;
  font-weight: 700; }

.btn-half:hover, .btn-full:hover {
  opacity: 0.6;
  transition: all 0.5s ease-in; }

.btn-primary {
  color: #fff !important; }

.copy {
  width: 70%;
  margin-left: 15%;
  margin-bottom: 20px; }


h3:after {
  width: 100px;
  height: 2px;
  //background-color: $hauptfarbe
  display: block;
  content: " ";
  margin: 0 auto;
 }  //margin-top: 30px


.features {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #fff;
  h3 {
    margin-top: 30px;
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 30px; }
  img {
    margin-right: 20px; }
  i {
    font-size: 40px;
    color: $hauptfarbe;
    margin-left: 35%;
    line-height: 50px;
    margin-bottom: 20px; }
  h4 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 2px !important;
    margin-bottom: 20px !important; }
  li {
    font-size: 16px; }
  ul {
    margin-left: 20px; } }

.arranging {
  text-align: left;
  line-height: 28px; }

p {
  font-size: 16px; }


.star.empty {
  height: 25px;
  width: 23px;
  display: inline-block;
  background: url("../img/star_off_mini.png") no-repeat; }

.star {
  height: 25px;
  width: 23px;
  display: inline-block;
  background: url("../img/star_mini.png") no-repeat; }

.jobliste {
  padding-top: 130px;
  padding-bottom: 30px;
  h3 {
    margin-top: 80px;
    margin-bottom: 30px;
    font-weight: 300; }
  i {
    font-size: 40px;
    color: $hauptfarbe;
    margin-left: 35%;
    line-height: 50px;
    margin-bottom: 20px; }
  h4 {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 5px; }
  padding-top: 50px !important; }


.farbig {
  background: $hauptfarbe;
  color: #fff; }

.usfarbig {
  background: $grauton; }


.pic img {
  width: 80%; }


.navbar-toggle {
  padding: 0; }


footer {
  background: $footerfarbe;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
  li {
    list-style: none; }

  a {
    color: white;
    text-decoration: none;
    &:hover {
      color: $hauptfarbe;
      transition: all 0.3s ease-in;
      text-decoration: none; } }


  h4 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 100; }

  .titel {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 100; }


  i {
    color: #fff;
    width: 30px;
    .social {
      color: #fff; } } }


.hell {
  background: #ffffff !important;
  color: #337ab7 !important;
  font-weight: 700 !important;
  font-size: 1.1em !important;
  border-radius: 10px; }

.navibuttonhell {
  background: #ffffff !important;
  color: #337ab7 !important;
  font-weight: 500; }

.social-icons .fa {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #FFF;
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  font-size: 1.4em;
  margin-right: 10px; }


.fa-facebook {
  background: #3C599F; }
.fa-xing {
  background: #007976; }
.fa-linkedin {
  background: #0072B3; }
.fa-twitter {
  background: #32CCFE; }


.fa-instagram {
  background: #A1755C; }


.fa-google-plus {
  background: #CF3D2E; }


footer .linie {
  width: 100%;
  border-bottom: 1px solid $hauptfarbe;
  margin-bottom: 10px; }


.copyright {
  background: #333;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff; }


.navbar-fixed-bottom, .navbar-fixed-top {
  z-index: 12000; }


.jobfilter {
  margin-top: 50px;
  padding-top: 60px;
  padding-bottom: 10px;
  background: #e5e2e2;
  .header_titel {
    font-size: 24px;
    margin-bottom: 10px; }
  h1 {
    color: #666; } }



.jobliste .row {
  margin-bottom: 15px; }

.blog {
  .blog_header {
    height: 400px;
    overflow: hidden; } }
.blog_container {
  padding: 10px;
  background: #fff;
  .blog_content {
    border: 1px solid #ccc;
    height: 500px;
    .blog_header {
      height: 200px;
      overflow: hidden; }
    .blog_text {
      padding: 10px;
      p {
        line-height: 25px; } } }
  i {
    font-size: 15px;
    line-height: 20px;
    margin: 0; }
  .buttons {
    position: absolute;
    width: 100%;
    bottom: 20px;
    .btn {
      padding: 5px 15px;
      text-decoration: none;
      border-radius: 1px;
      margin-left: 10px; } } }
.hilight {
  color: $hilightfarbe !important; }


.job_container {
  height: 190px;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin: 0 20px 20px 0;
  img {
    max-width: 100%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .logoimg {
    max-width: 70%; }

  .logo {
    height: 170px;
    z-index: 20;
    text-align: center;
    width: 85%;
    margin-top: 10px; }

  p {
    color: #666;
    line-height: 22px;
    font-size: 100%; }

  i {
    font-size: 15px;
    line-height: 20px;
    margin: 0; }

  .location {
    margin-bottom: 10px; }

  .buttons {
    position: absolute;
    width: 100%;
    bottom: 10px;
    .btn {
      padding: 5px 15px;
      text-decoration: none;
      border-radius: 1px;
      margin-right: 5px; } } }

.job_header {
  position: absolute;
  top: 0;
  left: 0;
  height: 188px;
  overflow: hidden;
  z-index: 0; }

.jobliste_links {
  width: 40%;
  float: left; }

.jobliste_rechts {
  width: 60%;
  float: right;
  .jobmetatext {
    height: 70px;
    overflow: hidden; }
  .titel {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 100; } }

.buttons i {
  color: #fff;
  padding: 0; }


.fa-times {
  left: 10px;
  top: 10px;
  opacity: 0.7;
  cursor: pointer;
  position: absolute;
  color: white;
  transition: all 0.2s ease-in-out;
  z-index: 13000;
  &:hover {
    opacity: 1; } }



.sidebar_menu {
  position: fixed;
  width: 350px;
  margin-left: 0px;
  margin-top: 50px;
  overflow: scroll;
  height: 100vh;
  max-height: 100vh;
  background: #555;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  color: #fff;
  .btn-group {
    margin-bottom: 5px; } }


.hide_menu {
  margin-left: -355px;
  transition: all 0.3s ease-in-out; }


.toggle_menu {
  position: fixed;
  padding: 15px 20px 15px 15px;
  margin-top: 52px;
  color: white;
  cursor: pointer;
  background-color: $gruenton;
  opacity: 0;
  z-index: 10000;
  font-size: 2em;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
  border: 0; }


.toggle_menu_close {
  padding: 15px 20px 15px 15px;
  margin-left: -10px;
  margin-top: -10px;
  color: white;
  cursor: pointer;
  background-color: $hauptfarbe;
  opacity: 1;
  z-index: 10000;
  font-size: 2.01em;
  transition: all 0.2s ease-in-out; }


.opacity_one {
  opacity: 1;
  transition: all 0.2s ease-in-out; }


.toggle_menu:hover, .toggle_menu_close:hover {
  background-color: #FE4365;
  transition: all 0.2s ease-in-out; }


.jobfilter_formular {
  margin-top: 30px;
  padding: 10px;
  .dropdown-menu > li > a {
    color: $schriftfarbe; }
  a {
    color: #fff;
    &:hover, &:active {
      color: $hauptfarbe;
      text-decoration: none;
      transition: all 0.5s ease-in; } } }




#js_send {
  background: #666;
  color: #fff;
  width: 90%;
  float: right;
  &:hover {
    color: #fff;
    text-decoration: none;
    background: $hauptfarbe;
    transition: all 0.5s ease-in; } }

.js_del {
  margin-top: 11px;
  float: left; }

.jobfilter_formular form {
  background: #555;
  input {
    margin: 1% 1% 10 1%;
    width: 99%;
    padding: 3%;
    display: block;
    border: 1px solid #dadada;
    background: #fff;
    color: #000;
    &:focus {
      border: 1px solid #dadada; }

    &[type="checkbox"] {
      display: inline;
      width: 20px; } }


  label {
    font-weight: 200; } }



.errspan {
  float: right;
  margin-right: 16px;
  margin-top: -36px;
  position: relative;
  z-index: 2;
  color: #333; }


#slider {
  margin-bottom: 15px;
  .ui-state-active {
    background: $hauptfarbe; } }



.checkgruppe {
  width: 100%;
  border-top: 1px #eeeeee solid;
  padding: 10px 0 10px 0;
  li {
    list-style: none; }
  li ul {
    display: none; }
  li ul.supOpen {
    display: block;
    transition: all 0.5s ease-in;
    background: #eee;
    padding: 5px;
    color: #000; }
  ul {
    margin-bottom: 0px; } }



#sociallogin {
  a {
    float: left;
    margin-bottom: 10px; } }


.funktionen {
  color: #fff; }


.suche_sub {
  display: none; }


.sub_open {
  margin-top: 10px;
  display: block;
  transition: all 0.5s ease-in;
  background: #eee;
  padding: 5px;
  color: #000; }


.navi_links {
  margin-top: -50px; }


.box {
  min-height: 100px;
  padding: 10px;
  background: #eee;
  border-radius: 3px;
  line-height: 35px;
  margin-bottom: 20px; }

.sidebar {
  padding: 10px;
  background: #eee;
  border-radius: 3px;
  line-height: 35px;
  margin-bottom: 20px; }


.abstract {
  font-size: 20px; }

.dropdownfarbig {
  background-color: transparent !important; }

.open {
  background-color: transparent !important; }

.open {
  a {
    background: #333 !important;
    border-bottom: 4px solid $hauptfarbe !important; } }

.dropdown-menu > li > a {
  background-color: transparent !important;
  border-bottom: 0 !important; }

.multiselect-container {
  max-height: 250px !important;
  overflow: auto;
  width: 330px; }

.dropdown-menu > .active > a {
  color: $hauptfarbe !important; }

.dropdown-menu {
  position: relative !important; }

.sharebox {
  display: none;
  position: absolute;
  z-index: 1000;
  background: #1ecacd;
  padding: 10px;
  margin-top: 2px;
  left: 0px; }
.sharebox_show {
  display: block; }

.jobdetails i {
  color: #ffffff !important;
  font-size: 20px !important;
  margin-left: 0 !important;
  line-height: 20px;
  margin-bottom: 0px; }

.modalbutton {
  width: 255px !important;
  margin-bottom: 10px; }

.formfeld {
  margin-bottom: 10px; }
.checkfeld {
  color: $errorcolor; }



.dropzone_rahmen {
  width: 100%;
  background: $hauptfarbe;
  padding: 10px; }

.dropzone {
  width: 100%;
  height: 60px;
  border: 2px dashed #fff;
  color: #fff;
  line-height: 60px;
  text-align: center;
  margin: 0 auto; }
.dragover {
  border-color: #000;
  color: #000; }

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  + label {
    font-size: 1.1em;
    font-weight: 500;
    color: white;
    background-color: #288BCC;
    display: inline-block;
    padding: 5px; }
  &:focus + label {
    background-color: red; }
  + label {
    &:hover {
      background-color: red; }
    cursor: pointer; } }

#uploads {
  margin-top: 10px; }


.dash-box {
  p,li {
    font-size: 14px;
    line-height: 20px; } }
#errorbox {
  display: none;
  .achtung {
    color: $errorcolor; } }
#feedback {
  display: none;
  color: $feedbackcolor; }

#mobile_footer {
  display: none;
  background: $footerfarbe;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 45px;
  padding-top: 12px;
  a {
    padding: 5px; } }

.desktop_footer {
  display: block; }
.mobile_naviplus {
  display: none; }
.modal {
  z-index: 99999; }


.fixed {
  position: fixed !important;
  top: 50px !important;
  z-index: 10000 !important;
  width: 360px; }

.fixedbox {
  position: fixed !important;
  top: 50px !important;
  z-index: 10000 !important;
  width: 90% !important;
  max-width: 350px !important; }

.infos_titel {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
  color: $schriftfarbe !important; }

.pforgot {
  display: none; }

#mojobprofil {
  display: none;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .titel {
    background: #17AFB4;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 5px; } }
#mojobbutton {
  .glyphicon {
    color: #fff !important; } }
//#weiterer_arbeitgeber
//  margin-top: 20px

.warbeitgeber {
  margin-top: 10px; }
.linie {
  margin-top: 10px;
  border-bottom: 1px $hauptfarbe solid; }

.rund {
  border-radius: 10%; }
.smallbutton {
  background: white; }

.smallbutton:hover {
  background: #FE4365;
  transition: all 0.5s ease-in; }
.feedback {
  color: #FE4365; }
.badge {
  background: #FF4262; }

.navbar-brand {
  padding: 5px 15px !important; }

.grau {
  color: #cccccc; }

#druck {
  font-size: 16px !important; }

.footermenue {
  padding-left: 10px !important;
  padding-right: 5px !important; }

.abstand-left {
  padding-left: 10px; }
.abstand-oben {
  margin-top: -40px; }

#jetztbewerben {
  text-align: center; }

h1 {
  font-size: 5.5rem !important; }

.img-responsive {
  display: inline !important; }

.col-sm-10 {
  margin-left: 7% !important; }


@media (max-device-width: 1024px) {
  /* Breite beträgt höchstens 1024px */
  .hero, hero_detailseite {
    width: 60%; }
  .jssocials-share-whatsapp {
    display: none; }
  .fixedbox {
    width: 303px; }
  .hero_detailseite {
    margin-top: 50px; }
  h1 {
    font-size: 3.5rem !important; } }

@media (max-device-width: 991px) {
  /* Breite beträgt höchstens 991px */
  .features i {
    margin-left: 0;
    margin-bottom: 0; }
  .jssocials-share-whatsapp {
    display: none; }
  h1 {
    font-size: 2.2rem !important;
    line-height: 30px !important; } }
@media (min-device-width: 768px) and (max-device-width: 1199px) {
  .jobliste_rechts {
    padding-left: 50px;
    .jobmetatext {
      height: 60px; } }
  .jobliste h4 {
    font-size: 16px; }
  .job_container {
    .logoimg {
      margin-left: 60px; }
    p {
      line-height: 19px; } }
  .jssocials-share-whatsapp {
    display: none; }
  .infos_titel {
    font-size: 18px; }
  .jobfilter {
    .col-xs-12 {
      padding-left: 150px; } }
  .job_header {
    width: 45% !important; }
  .features {
    li {
      font-size: 20px !important; } } }

@media (max-device-width: 767px) {
  /* Breite beträgt höchstens 767px */
  .callaction {
    margin-left: 0px;
    font-size: 1em; }
  .hero, .hero_detailseite {
    left: 33%;
    width: 300px;
    text-align: center;
    h2 {
      font-size: 20px; } }

  .jssocials-share-whatsapp {
    display: block;
    float: left; }
  .hero_detailseite {
    margin-left: 0px; }
  .hero_detailseite {
    span {
      font-size: 18px; } }
  .pforgot {
    display: block !important; }

  .job_header {
    width: 100% !important;
    text-align: center; }

  .fixed {
    position: fixed !important;
    top: 50px !important;
    z-index: 10000 !important;
    width: 91% !important; }

  .link {
    padding-bottom: 20px; }

  .blog_container .blog_content .blog_text p {
    font-size: 14px;
    line-height: 20px; }

  .dropdown-menu > li > a {
    color: #fff; }

  .features {
    padding-bottom: 0px;
    .mobile_content {
      margin-left: 0px;
      margin-right: 0px; }
    p,li {
      font-size: 16px;
      line-height: 26px; }
    h3 {
      margin-top: 15px;
      margin-bottom: 5px;
      font-size: 22px; }
    h4 {
      font-size: 18px; }
    /*.img-responsive
      width: 100% !important*/ }

  .jobmetatext {
    p,li {
      font-size: 16px;
      line-height: 26px; } }

  .jobfilter {
    text-align: right;
    padding-bottom: 0px !important; }
  .jobdetails h3::after {
    margin-top: 10px; }

  .toggle_menu {
    padding: 10px;
    font-size: 1.2em; }

  .hero h1, .hero_detailseite h1 {
    font-size: 18px;
    line-height: 25px; }
  .headerjobdetails {
    height: 35vH; }
  .hero_detailseite {
    margin-top: 50px; }
  .logo {
    img {
      max-width: 250px !important; } }
  .mobile_naviplus {
    display: block;
    margin-left: 15px; }

  .desktop_footer {}
  /*  display: none !important*/
  footer {
   /* display: none !important*/ }
  .copyright {
    display: none !important; }

  #mobile_footer {
    display: block !important;
    background: $footerfarbe; }

  #mobile_footer a.active {
    background-color: #1ECACD !important;
    color: #fff !important; }

  #mobile_footer a:hover {
    color: #fff !important;
    text-decoration: none; }

  .ui-mobile .ui-header, .ui-mobile .ui-footer {
    background: $footerfarbe; }
  .btn {
    margin-bottom: 10px; }

  .job_container .buttons .btn {
    font-size: 11px; }

  .button-awesome {
    text-align: center; }

  .navbar-collapse {
    background: #333; }

  .navbar-header {
    height: 50px; }

  .navbar-brand {
    margin-top: -50px; }

  .navbar a:hover {
    border-bottom: 1px solid $hauptfarbe; }

  .features i {
    margin-left: 0;
    margin-bottom: 0; }

  .job_container {
    margin-bottom: 20px;
    .row {
      margin-bottom: 0px; } }
  .jobliste h4 {
    font-size: 16px; }
  .job_header {
    height: 150px; }

  .job_container {
    height: 400px;
    margin: 0 0 20px 0;
    .buttons {
      bottom: 2px; }

    .logo {
      width: 100%;
      height: 130px; } }

  .jobliste_links {
    float: none;
    width: 100%; }

  .jobliste_rechts {
    float: none;
    width: auto;
    .jobmetatext {
      height: 110px;
      overflow: hidden; } }
  .dropzone_rahmen {
    width: 100%;
    background: none;
    padding: 0px; }

  .dropzone {
    display: none; }

  .col-xs-6 {
    width: 100% !important; } }

@media (max-device-width: 360px) {
  /*.features
    img
      width: 100% !important
      float: none !important*/
  iframe {
    width: 100% !important; } }
@media (min-width: 768px) {
  .container {
    /*max-width: 720px*/ } }
